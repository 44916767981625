
import { defineComponent, ref, Ref } from 'vue';
import {
    listPagination,
    ListHeaderItem,
    RequestData
} from '@/components/common/list';
import { format } from '@/methods/date';

const headers: Array<ListHeaderItem> = [{
    name: 'CreateTime',
    label: WordList.CaptureListTanleCaptureTime
}, {
    name: 'OperaType',
    label: WordList.TabelUserInHtmlRoleLabel
}, {
    name: 'Operator',
    label: WordList.CaptureListTanleInitiator
}, {
    name: 'IP',
    label: WordList.ProperAllTextIP
}, {
    name: 'Type',
    label: WordList.TabelKeyInHtmlType
}, {
    name: 'Action',
    label: WordList.CaptureListTanleAction
}];

const typeOptionList = [{
    label: WordList.ProperAllTextAuditCategoryLogin,
    value: 'Login'
}, {
    label: WordList.ProperAllTextAuditCategoryUser,
    value: 'User'
}, {
    label: WordList.ProperAllTextAuditCategoryAccess,
    value: 'Access'
}, {
    label: WordList.ProperAllTextAuditCategoryCommunity,
    value: 'Community'
}, {
    label: WordList.ProperAllTextAuditCategoryDevice,
    value: 'Device'
}];

const roleOptionList = [{
    label: WordList.ProperAllTextRoleSuperManage,
    value: 'SuperManager'
}, {
    label: WordList.ProperAllTextRoleDistributor,
    value: 'Distributor'
}, {
    label: WordList.ProperAllTextInstaller,
    value: 'Installer'
}, {
    label: WordList.ProperAllTextRolePM,
    value: 'PM'
}, {
    label: WordList.ProperAllTextRoleEndUser,
    value: 'Enduser'
}];

export default defineComponent({
    components: {
        listPagination
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'getallaudit',
            param: {
                Type: 'all',
                BeginTime: '',
                EndTime: '',
                UserType: 'all'
            }
        });

        return {
            headers,
            listRequestData,
            updateToList,
            typeOptionList,
            roleOptionList,
            format
        };
    }
});
